import React from "react"
import {graphql} from "gatsby"
import LocalizedLink from "../../components/localizedLink"

const Posts = ({ data: { allMdx } }) => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations

    return (
        <article className="top">
            <section className="news">
                <ul className="post-list flex between wrap">
                    {allMdx.edges.map(({ node: post }) => (
                        <LocalizedLink className={`news-link`} to={`/${post.parent.relativeDirectory}/`}>

                        <li key={`${post.frontmatter.title}-${post.fields.locale}`}>
                                <img className={`post-thumbnail`} src={`${post.frontmatter.featuredImage} `} alt=""/>
                            <div className="tag">
                                {(() => {
                                    switch (post.frontmatter.tag) {
                                        case "newProduct":   return <p className={`tag tag-new-product`}>新商品</p>;
                                        case "campaign": return <p className={`tag tag-campaign`}>キャンペーン</p>;
                                        case "exhibition":  return <p className={`tag tag-exhibition`}>展示会出展</p>;
                                        default:      return <p className={`tag tag-general`}>全般</p>;
                                    }
                                })()}
                            </div>
                            <p className="post-title">{post.frontmatter.title}</p>
                            <p className="date">{post.frontmatter.date}</p>
                        </li>
                        </LocalizedLink>

                    ))}
                </ul>
            </section>

        </article>
    )
}

export default Posts

export const query = graphql`
  query Posts($locale: String!, $dateFormat: String!) {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/(blog)/.*\\\\.mdx$/"}, fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: $dateFormat)
            featuredImage
            tag
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`
